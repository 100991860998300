import Color from 'color'
import { hasFeature } from '@lib/features'

export const UE_BLACK = '#131516'
export const DARK_BRANDED_BACKGROUND_COLOR = '#212529'
export const DARK_BRANDED_FOOTER_COLOR = '#212529'
export const LIGHT_BRANDED_FOOTER_COLOR = '#F8F9FA'

export type LogoSizeOptions = 'small' | 'large' | 'none'

// Supported colors on the Basic components
export type BasicColorOptions = 'primary' | 'secondary'

// Supported colors on the Footer components
export type BrandedFooterColorOptions = 'dark' | 'light'

// All supported color options for the branded components backgrounds
export type BrandedBackgroundColorOption = BasicColorOptions | 'dark' | 'both'

// Gets the appropriate background color for the StatBranded component
// based on the provided option
// Returns a hex string e.g. #FFF000 or an array containing two hex strings, in case of 'both' option
export function getBrandedBackgroundColor(
  account: object,
  backgroundColorOption: string,
): string | string[] {
  if (!hasColorsFlag(account)) return 'white'
  switch (backgroundColorOption) {
    case 'dark':
      return DARK_BRANDED_BACKGROUND_COLOR
    case 'both':
      return [account['primary_color'], account['secondary_color']]
    case 'primary':
      return account['primary_color']
    case 'secondary':
      return account['secondary_color']
    default:
      return 'white'
  }
}

// Gets a color from the account object based on the provided colorOption
export function getBasicColor(account: object, colorOption: BasicColorOptions): string {
  if (!hasColorsFlag(account)) return 'purple'
  switch (colorOption) {
    case 'primary':
      return account['primary_color']
    case 'secondary':
      return account['secondary_color']
    default:
      return 'purple'
  }
}

// Translates the footer color option into a hex value
export function getBrandedFooterColor(
  account: object,
  colorOption: BrandedFooterColorOptions,
): string {
  if (!hasColorsFlag(account)) return 'white'
  switch (colorOption) {
    case 'dark':
      return DARK_BRANDED_FOOTER_COLOR
    case 'light':
      return LIGHT_BRANDED_FOOTER_COLOR
    default:
      return 'white'
  }
}

// This is a port to JS of the 9-color schemes defined in _colors.sass.
// Used as a stopgap until we can finish migrating away from the old color schemes.
//
// Each valid account.color_scheme value is represented as an object key.
// The arrays map to the $<colorName>1 - $<colorName>9 variables defined in _variables.sass.
// E.g. legacyColorSchemes.pink[4] corresponds to the $pink5 sass variable.
const legacyColorSchemes = {
  pink: [
    Color('#FCE7F3'),
    Color('#FBCFE8'),
    Color('#F9A8D4'),
    Color('#F472B6'),
    Color('#EC4899'),
    Color('#DB2777'),
    Color('#BE185D'),
    Color('#9D174D'),
    Color('#831843'),
  ],
  purple: [
    Color('#EDE9FE'),
    Color('#DDD6FE'),
    Color('#C4B5FD'),
    Color('#A78BFA'),
    Color('#8B5CF6'),
    Color('#7C3AED'),
    Color('#6D28D9'),
    Color('#5B21B6'),
    Color('#4C1D95'),
  ],
  blue: [
    Color('#DBEAFE'),
    Color('#BFDBFE'),
    Color('#93C5FD'),
    Color('#60A5FA'),
    Color('#3B82F6'),
    Color('#2563EB'),
    Color('#1D4ED8'),
    Color('#1E40AF'),
    Color('#1E3A8A'),
  ],
  green: [
    Color('#D1FAE5'),
    Color('#A7F3D0'),
    Color('#6EE7B7'),
    Color('#34D399'),
    Color('#10B981'),
    Color('#059669'),
    Color('#047857'),
    Color('#065F46'),
    Color('#064E3B'),
  ],
  yellow: [
    Color('#FEF9C3'),
    Color('#FEF08A'),
    Color('#FDE047'),
    Color('#FACC15'),
    Color('#EAB308'),
    Color('#CA8A04'),
    Color('#A16207'),
    Color('#854D0E'),
    Color('#713F12'),
  ],
  orange: [
    Color('#FFEDD5'),
    Color('#FED7AA'),
    Color('#FDBA74'),
    Color('#FB923C'),
    Color('#F97316'),
    Color('#EA580C'),
    Color('#C2410C'),
    Color('#9A3412'),
    Color('#7C2D12'),
  ],
  red: [
    Color('#FEE2E2'),
    Color('#FECACA'),
    Color('#FCA5A5'),
    Color('#F87171'),
    Color('#EF4444'),
    Color('#DC2626'),
    Color('#B91C1C'),
    Color('#991B1B'),
    Color('#7F1D1D'),
  ],
  grey: [
    Color('#F3F4F6'),
    Color('#E5E7EB'),
    Color('#D1D5DB'),
    Color('#9CA3AF'),
    Color('#6B7280'),
    Color('#4B5563'),
    Color('#374151'),
    Color('#1F2937'),
    Color('#111827'),
  ],
}

// Returns the 9-color array for the named account.color_scheme
// Throws an error if given an unsupported color scheme name
// Defaults to grey if no color scheme is provided
export function getColorScheme(colorSchemeName) {
  if (!colorSchemeName) return legacyColorSchemes.grey

  const scheme = legacyColorSchemes[colorSchemeName]
  if (scheme) return scheme

  throw new Error(`Invalid color scheme: ${colorSchemeName}`)
}

// Generate a color scale from a base color with a given number of steps
// We start from a darker color to provide more contrast
// The whole approach is based on experimentation, possibly application of
// color theory or maybe a 3rd party color scale generation lib could
// product better results.
export function generateColorScale(baseColor, steps) {
  const startColor = Color(baseColor).darken(0.3)

  const scale = [startColor.hex()]

  for (let i = 1; i < steps; i++) {
    const factor = (i / steps) * 1.2
    scale.push(startColor.lighten(factor).hex())
  }

  return scale.reverse()
}

export function hasColorsFlag(account) {
  return hasFeature(account, 'colors')
}

// Intended to be imported as a computed on a component, and the component
// needs to have colorsFlagEnabled computed as well as an account attr.
//
// Returns a hex string e.g. #FFF000
// Example:
// <template>
// div :style="{ backgroundColor: brandColor }">
// ...
// </template>
// ...
// computed: {
//   brandColor,
//   colorsFlagEnabled,
// }
export function brandLinkColor(): string {
  // The old version of this used color #5 of the legacy 9-color hardcoded schemes.
  return hasColorsFlag(this.account)
    ? this.account?.primary_color
    : getColorScheme(this.account?.color_scheme)[4]
}

// Returns an array of `count` length for hex values
//
// Note: If colors flag is off, we'll use the old 9-color hardcoded scheme.
// This means that if `count` argument is > 9 we'll just return 9 colors.
export function brandColorScheme(count): string[] {
  return hasColorsFlag(this.account)
    ? generateColorScale(this.account?.primary_color, count)
    : getColorScheme(this.account?.color_scheme)
        .slice(0, count)
        .map((color) => color.hex())
}

// Returns an array of `count` length for hex values
//
// Note: essentially the same as brandColorScheme, but allows for a custom color
// to be passed in as the base color for the scale.
// Decided to create a new function instead of just expanding the existing one,
// because a new function allows us to move quickly and not worry about breaking
// existing code that might be using brandColorScheme, which would require big rounds of QA.
// TODO: Unify brandColorSchemeForColor and brandColorScheme
export function brandColorSchemeForColor(providedColor, count): string[] {
  return hasColorsFlag(this.account)
    ? generateColorScale(providedColor, count)
    : getColorScheme(this.account?.color_scheme)
        .slice(0, count)
        .map((color) => color.hex())
}

// Returns a hex string e.g. #FFF000
// representing an appropriate brand color to use for header background colors.
export function headerColor(): string {
  return hasColorsFlag(this.account) ? this.account?.primary_color : this.account?.brand_color_1
}

// Returns a an array of hsl strings
// representing an appropriate brand color to use for header background colors.
export function gradientColors(): string[] {
  const startColor = hasColorsFlag(this.account)
    ? Color(this.account?.primary_color).hsl()
    : this.account?.gradient_1 || 'hsl(270, 100%, 40%)'

  const endColor = hasColorsFlag(this.account)
    ? Color(this.account?.primary_color).darken(0.2).hsl()
    : this.account?.gradient_2 || 'hsl(270, 100%, 40%)'

  return [startColor, endColor]
}

// Determines whether, base do the account, we should use light or dark
// text on top of any brand-color background.
//
// The legacy logic uses a flag on the account, but once we eliminate
// that when colors flag is removed, this should probably just be a pure function
// that takes a color as an argument rather than relying on this.account coupling.
export function useDarkText(): boolean {
  if (!this.account) return false

  if (hasColorsFlag(this.account)) {
    return Color(this.account.primary_color).isLight()
  } else {
    return this.account.dark_text
  }
}

// As mentioned in the comment for the function above, this should
// replace it when we remove the account dependency.
// Creating it now just so we centralize the colors logic in one place.
// TODO: Rename it to `useDarkText` when this happens.
export function useDarkTextForColor(color): boolean {
  return Color(color).isLight()
}
